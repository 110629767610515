// src/App.js
import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Projects from './pages/Projects';
import Inquiries from "./pages/Inquiries";
import PORequestPage from "./pages/PORequestPage";
import LoginPage from "./pages/LoginPage";
import NotificationsInbox from "./components/NotificationsInbox";
import { getNotifications } from "./services/api"; // Import notifications API call

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [notifications, setNotifications] = useState([]); // State for notifications
  const navigate = useNavigate();

  // Load login state and notifications on app load
  useEffect(() => {
    const userLoggedIn = localStorage.getItem("isLoggedIn");
    const storedUserId = localStorage.getItem("userId");

    if (userLoggedIn === "true" && storedUserId) {
      setIsLoggedIn(true);
      setUserId(storedUserId);
      fetchNotifications(storedUserId); // Fetch notifications for the user
    }
  }, []);

  // Fetch notifications for the current user
  const fetchNotifications = async (currentUserId) => {
    try {
      const data = await getNotifications(currentUserId);
      setNotifications(data); // Store fetched notifications
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Login handler
  const handleLogin = (id) => {
    setIsLoggedIn(true);
    setUserId(id);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("userId", id);
    fetchNotifications(id); // Fetch notifications upon login
    navigate("/dashboard");
  };

  // Logout handler
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserId(null);
    setNotifications([]);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userId");
    navigate("/login");
  };

  return (
    <div className="container-fluid">
      {/* Pass notifications and isLoggedIn to Navbar */}
      <Navbar isLoggedIn={isLoggedIn} notifications={notifications} />

      <div className="row">
        <div className="col-12">
          <Routes>
            <Route path="/" element={<h1 className="text-center mt-5">Bienvenido al ERP de construcción</h1>} />
            <Route path="/login" element={<LoginPage handleLogin={handleLogin} />} />
            <Route path="/dashboard" element={isLoggedIn ? <Dashboard handleLogout={handleLogout} /> : <Navigate to="/login" />} />
            <Route path="/users" element={isLoggedIn ? <Users /> : <Navigate to="/login" />} />
            <Route path="/projects" element={isLoggedIn ? <Projects /> : <Navigate to="/login" />} />
            <Route path="/inquiries" element={isLoggedIn ? <Inquiries userId={userId} /> : <Navigate to="/login" />} />
            <Route path="/pos" element={isLoggedIn ? <PORequestPage userId={userId} /> : <Navigate to="/login" />} />
            <Route path="/notifications" element={isLoggedIn ? <NotificationsInbox notifications={notifications} userId={userId} /> : <Navigate to="/login" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
