import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Form, Card, Spinner, Alert } from "react-bootstrap";
import { getProjects, deleteProject, getQuote, getBudget, getBudgetItems } from "../services/api";

const ProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const [budgets, setBudgets] = useState([]);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);
  const [budgetItems, setBudgetItems] = useState([]);

  const [loadingProjects, setLoadingProjects] = useState(true);
  const [loadingBudgets, setLoadingBudgets] = useState(false);
  const [loadingBudgetItems, setLoadingBudgetItems] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await getProjects();
        setProjects(data);
      } catch (err) {
        setError("Error fetching projects.");
      } finally {
        setLoadingProjects(false);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchBudgets = async () => {
      if (!selectedProjectId) return;

      setLoadingBudgets(true);
      setBudgets([]);
      setSelectedBudgetId(null);
      setBudgetItems([]);

      try {
        const data = await getBudget(selectedProjectId);
        setBudgets(data);
      } catch {
        setError("Error fetching budgets.");
      } finally {
        setLoadingBudgets(false);
      }
    };

    fetchBudgets();
  }, [selectedProjectId]);

  useEffect(() => {
    const fetchBudgetItems = async () => {
      if (!selectedBudgetId) return;

      setLoadingBudgetItems(true);
      setBudgetItems([]);

      try {
        const data = await getBudgetItems(selectedProjectId,selectedBudgetId);
        setBudgetItems(data);
      } catch {
        setError("Error fetching budget items.");
      } finally {
        setLoadingBudgetItems(false);
      }
    };

    fetchBudgetItems();
  }, [selectedBudgetId]);

  const handleProjectChange = (e) => setSelectedProjectId(e.target.value);
  const handleBudgetClick = (budgetId) => setSelectedBudgetId(budgetId);

  return (
    <Container fluid className="App">
      <h1>Gestión de Proyectos</h1>

      {error && <Alert variant="danger">{error}</Alert>}

      {loadingProjects ? (
        <Spinner animation="border" />
      ) : (
        <>
          {/* Project Selector */}
          <Form.Group className="project-select">
            <Form.Label>Selecciona un proyecto:</Form.Label>
            <Form.Control as="select" onChange={handleProjectChange} value={selectedProjectId || ""} style={{ width: "300px" }}>
              <option value="">-- Selecciona un proyecto --</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {selectedProjectId && (
            <Card className="mb-4">
              <Card.Header>Presupuestos</Card.Header>
              <Card.Body>
                {loadingBudgets ? (
                  <Spinner animation="border" />
                ) : budgets.length > 0 ? (
                  <Table bordered hover className="project-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Descripción</th>
                        <th>Total</th>
                        <th>Restante</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {budgets.map((budget) => (
                        <tr key={budget.budget_id}>
                          <td>{budget.budget_id}</td>
                          <td>{budget.description}</td>
                          <td>${Number(budget.TotalAmount).toLocaleString()}</td>
                          <td>${Number(budget.remaining_budget).toLocaleString()}</td>                          
                          <td>
                            <Button variant="info" size="sm" onClick={() => handleBudgetClick(budget.budget_id)}>
                              Ver Detalles
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>No hay presupuestos disponibles.</p>
                )}
              </Card.Body>
            </Card>
          )}

          {selectedBudgetId && (
            <Card>
              <Card.Header>Detalles del Presupuesto</Card.Header>
              <Card.Body>
                {loadingBudgetItems ? (
                  <Spinner animation="border" />
                ) : budgetItems.length > 0 ? (
                  <Table bordered hover className="item-table">
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Costo Unitario</th>
                        <th>Costo Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {budgetItems.map((item) => (
                        <tr key={item.budget_item_id}>
                          <td>{item.costCode}</td>
                          <td>{item.description}</td>
                          <td>{item.amount}</td>
                          <td>${item.unitCost}</td>
                          <td>${(item.amount * item.unitCost).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>No hay detalles disponibles.</p>
                )}
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </Container>
  );
};

export default ProjectsPage;
