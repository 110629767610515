import React, { useEffect, useState } from "react";
import { Table, Button, Form, Modal, Alert, Container } from "react-bootstrap";
import { getProjects, getBudget, getInquiries, addInquiry,approveInquiry, deleteInquiry, updateInquiry } from "../services/api";

const InquiriesPage = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);
  const [inquiries, setInquiries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState(null);
  const [currentInquiry, setCurrentInquiry] = useState({
    description: "",
    quantity: 0,
    budget_id: null,
    inquiry_id: null,
  });

  const userHasPermission = true; // Simulating user permissions
  const currentUserId = 2;

  // Fetch all projects on component load
  useEffect(() => {
    const fetchProjects = async () => {
      const projectsData = await getProjects();
      setProjects(projectsData);
    };
    fetchProjects();
  }, []);

  // Fetch budgets and inquiries when a project is selected
  useEffect(() => {
    const fetchBudgetsAndInquiries = async () => {
      if (!selectedProjectId) return;

      const budgetData = await getBudget(selectedProjectId);
      setBudgets(budgetData);

      const inquiriesData = await getInquiries(selectedProjectId);
      setInquiries(inquiriesData);
    };
    fetchBudgetsAndInquiries();
  }, [selectedProjectId]);

  const handleAddEditInquiry = () => {
    if (!currentInquiry.description || currentInquiry.quantity <= 0 || !currentInquiry.budget_id) {
      setAlert("Please provide valid inquiry details and select a budget.");
      return;
    }

    const selectedBudget = budgets.find((b) => b.budget_id === currentInquiry.budget_id);
    if (currentInquiry.quantity > selectedBudget.remaining_budget) {
      setAlert("Inquiry quantity exceeds the remaining budgeted material!");
      return;
    }

    if (currentInquiry.inquiry_id) {
      // Edit existing inquiry
      updateInquiry(currentInquiry).then(() => {
        setInquiries((prev) =>
          prev.map((inquiry) =>
            inquiry.inquiry_id === currentInquiry.inquiry_id ? currentInquiry : inquiry
          )
        );
      });
    } else {
      // Add new inquiry
      addInquiry({ ...currentInquiry, project_id: selectedProjectId }).then((newInquiry) => {
        setInquiries((prev) => [...prev, newInquiry]);
      });
    }

    setShowModal(false);
    setCurrentInquiry({ description: "", quantity: 0, budget_id: null, inquiry_id: null });
    setAlert(null);
  };

  
  const handleApproveInquiry = async (inquiryId) => {
    try {
      await approveInquiry(selectedProjectId,inquiryId, currentUserId); // Call the API from api.js
      setInquiries((prevInquiries) =>
        prevInquiries.map((inq) =>
          inq.inquiry_id === inquiryId ? { ...inq, status_id: "approved" } : inq
        )
      );
      setAlert("Requisición aprobada!");
    } catch (error) {
      setAlert(error.message || "No se pudo aprobar la requisición.");
    }
  };

  

  const handleDeleteInquiry = (inquiryId) => {
    deleteInquiry(inquiryId).then(() => {
      setInquiries((prev) => prev.filter((inq) => inq.inquiry_id !== inquiryId));
    });
  };

  const openNewInquiryModal = () => {
    setCurrentInquiry({ description: "", quantity: 0, budget_id: null, inquiry_id: null });
    setShowModal(true);
  };

  const openEditModal = (inquiry) => {
    setCurrentInquiry(inquiry);
    setShowModal(true);
  };

  return (
    <Container className="mt-4">
      <h1>Gestión de Solicitudes</h1>

      {/* Select Project */}
      <Form.Group>
        <Form.Label>Selecciona un Proyecto:</Form.Label>
        <Form.Control
          as="select"
          onChange={(e) => setSelectedProjectId(e.target.value)}
          value={selectedProjectId || ""}
        >
          <option value="">-- Seleccionar --</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Budgets Table */}
      <h3>Presupuestos</h3>
      <Table bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Descripción</th>
            <th>Total</th>
            <th>Restante</th>
          </tr>
        </thead>
        <tbody>
          {budgets.map((budget) => (
            <tr
              key={budget.budget_id}
              style={
                budget.remaining_budget <= budget.TotalAmount * 0.1
                  ? { backgroundColor: "#f8d7da" } // Highlight in light red
                  : {}
              }
            >
              <td>{budget.budget_id}</td>
              <td>{budget.description}</td>
              <td>${Number(budget.TotalAmount).toLocaleString()}</td>
              <td>${Number(budget.remaining_budget).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Inquiries Table */}
      <h3>Solicitudes</h3>
      <Button className="mb-3" onClick={openNewInquiryModal}>
        Nueva Solicitud
      </Button>
      <Table bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Descripción</th>
            <th>Cantidad</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {inquiries.map((inquiry) => (
            <tr key={inquiry.inquiry_id}>
              <td>{inquiry.inquiry_id}</td>
              <td>{inquiry.description}</td>
              <td>{inquiry.quantity}</td>
              <td>{inquiry.status}</td>
              <td>
                <Button variant="warning" size="sm" onClick={() => openEditModal(inquiry)}>
                  Editar
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteInquiry(inquiry.inquiry_id)}
                >
                  Eliminar
                </Button>
                {userHasPermission && inquiry.status_id !== "approved" && (
                    <Button
                    variant="success"
                    size="sm"
                    className="ms-2"
                    onClick={() => handleApproveInquiry(inquiry.inquiry_id)}>
                    Aprobar
                    </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for New/Edit Inquiry */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentInquiry.inquiry_id ? "Editar Solicitud" : "Nueva Solicitud"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Presupuesto Asociado</Form.Label>
              <Form.Control
                as="select"
                value={currentInquiry.budget_id || ""}
                onChange={(e) =>
                  setCurrentInquiry({ ...currentInquiry, budget_id: e.target.value })
                }
              >
                <option value="">-- Seleccionar --</option>
                {budgets.map((budget) => (
                  <option key={budget.budget_id} value={budget.budget_id}>
                    {budget.description} (${Number(budget.remaining_budget).toLocaleString()} restante)
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese descripción"
                value={currentInquiry.description}
                onChange={(e) =>
                  setCurrentInquiry({ ...currentInquiry, description: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cantidad</Form.Label>
              <Form.Control
                type="number"
                placeholder="Ingrese cantidad"
                value={currentInquiry.quantity}
                onChange={(e) =>
                  setCurrentInquiry({ ...currentInquiry, quantity: parseFloat(e.target.value) })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleAddEditInquiry}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default InquiriesPage;
