import React, { useEffect, useState } from "react";
import { Table, Button, Form, Modal, Alert, Container } from "react-bootstrap";
import {
  getProjects,
  getInquiries,
  getPOs,
  addPO,
  updatePO,
  deletePO,
} from "../services/api";

const PORequestPage = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const [inquiries, setInquiries] = useState([]);
  const [selectedInquiryId, setSelectedInquiryId] = useState(null);

  const [POs, setPOs] = useState([]);
  const [currentPO, setCurrentPO] = useState({
    po_number: "",
    description: "",
    total_amount: "",
    buyerId: "",
    status_id: "pendiente",
  });

  const [alert, setAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch projects on load
  useEffect(() => {
    const fetchProjects = async () => {
      const projectsData = await getProjects();
      setProjects(projectsData);
    };
    fetchProjects();
  }, []);

  // Fetch inquiries when project is selected
  useEffect(() => {
    const fetchInquiries = async () => {
      if (!selectedProjectId) return;
      const inquiriesData = await getInquiries(selectedProjectId);
      setInquiries(inquiriesData);
    };
    fetchInquiries();
  }, [selectedProjectId]);

  // Fetch POs when inquiry is selected
  useEffect(() => {
    const fetchPOs = async () => {
      if (!selectedInquiryId) return;
      const POsData = await getPOs(selectedProjectId,selectedInquiryId);
      setPOs(POsData);
    };
    fetchPOs();
  }, [selectedInquiryId]);

  const handleSavePO = async () => {
    if (!currentPO.po_number || !currentPO.total_amount) {
      setAlert("Por favor, complete todos los campos obligatorios.");
      return;
    }

    try {
      if (currentPO.po_id) {
        await updatePO(currentPO);
        setPOs((prev) =>
          prev.map((po) => (po.po_id === currentPO.po_id ? currentPO : po))
        );
      } else {
        const newPO = await addPO({
          ...currentPO,
          project_id: selectedProjectId,
          inquiry_id: selectedInquiryId,
        });
        setPOs((prev) => [...prev, newPO]);
      }
      setAlert("Orden de compra guardada correctamente.");
      setShowModal(false);
      setCurrentPO({
        po_number: "",
        description: "",
        total_amount: "",
        buyerId: "",
        status_id: "pendiente",
      });
    } catch (error) {
      setAlert("Error al guardar la orden de compra.");
      console.error(error);
    }
  };

  const handleDeletePO = async (poId) => {
    try {
      await deletePO(poId);
      setPOs((prev) => prev.filter((po) => po.po_id !== poId));
      setAlert("Orden de compra eliminada correctamente.");
    } catch (error) {
      setAlert("Error al eliminar la orden de compra.");
    }
  };

  const openEditModal = (po) => {
    setCurrentPO(po);
    setShowModal(true);
  };

  const openNewPOModal = () => {
    setCurrentPO({
      po_number: "",
      description: "",
      total_amount: "",
      buyerId: "",
      status_id: "pendiente",
    });
    setShowModal(true);
  };

  return (
    <Container className="mt-4">
      <h1>Solicitudes de Órdenes de Compra</h1>

      {alert && <Alert variant="info">{alert}</Alert>}

      {/* Select Project */}
      <Form.Group>
        <Form.Label>Selecciona un Proyecto:</Form.Label>
        <Form.Control
          as="select"
          value={selectedProjectId || ""}
          onChange={(e) => setSelectedProjectId(e.target.value)}
        >
          <option value="">-- Seleccionar Proyecto --</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Select Inquiry */}
      {selectedProjectId && (
        <Form.Group className="mt-3">
          <Form.Label>Selecciona una Solicitud:</Form.Label>
          <Form.Control
            as="select"
            value={selectedInquiryId || ""}
            onChange={(e) => setSelectedInquiryId(e.target.value)}
          >
            <option value="">-- Seleccionar Solicitud --</option>
            {inquiries.map((inquiry) => (
              <option key={inquiry.inquiry_id} value={inquiry.inquiry_id}>
                {inquiry.description}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}

      {/* POs Table */}
      {selectedInquiryId && (
        <>
          <Button className="mt-3 mb-3" onClick={openNewPOModal}>
            Nueva Orden de Compra
          </Button>
          <Table bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Número</th>
                <th>Descripción</th>
                <th>Monto Total</th>
                <th>Comprador</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {POs.map((po) => (
                <tr key={po.po_id}>
                  <td>{po.po_id}</td>
                  <td>{po.po_number}</td>
                  <td>{po.description}</td>
                  <td>${Number(po.total_amount).toLocaleString()}</td>                  
                  <td>{po.buyer}</td>
                  <td>{po.status}</td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => openEditModal(po)}
                      className="me-2"
                    >
                      Editar
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeletePO(po.po_id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      {/* Modal for Create/Edit PO */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentPO.po_id ? "Editar Orden de Compra" : "Nueva Orden de Compra"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Número de Orden</Form.Label>
              <Form.Control
                type="text"
                value={currentPO.po_number}
                onChange={(e) =>
                  setCurrentPO({ ...currentPO, po_number: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                value={currentPO.description}
                onChange={(e) =>
                  setCurrentPO({ ...currentPO, description: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Monto Total</Form.Label>
              <Form.Control
                type="number"
                value={currentPO.total_amount}
                onChange={(e) =>
                  setCurrentPO({ ...currentPO, total_amount: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSavePO}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PORequestPage;
