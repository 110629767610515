import axios from 'axios';

const API_BASE_URL = 'https://rt5pmgczxepgaz3zclg2lssary0drgtr.lambda-url.us-west-1.on.aws/';
const API_BUDGET_URL = 'https://iektwg525ucvzm4pfzrlf6rney0fants.lambda-url.us-west-1.on.aws/';
const API_PROJECT_URL = 'https://1kh86gywll.execute-api.us-west-1.amazonaws.com/dev/';
//const API_PROJECT_URL = 'https://vm5jcfckninyt5urwihrtgy2ta0vzwuu.lambda-url.us-west-1.on.aws/';


// Function to fetch users from the API
export const getUsers = async () => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.error("Error al obtener los usuarios: ", error);
    throw error;
  }
};



export const getBudget = async (projectId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/budget`);
    console.log('Budget data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener presupuestos: ", error);
    throw error;
  }
};


export const getBudgetItems = async (projectId,budgetId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/budget/${budgetId}`);
    console.log('Budget details data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener presupuestos: ", error);
    throw error;
  }
};


export const getInquiries = async (projectId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/inquiry`);
    console.log('Inquiries data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener requisiciones: ", error);
    throw error;
  }
};

export const deleteInquiry = async (projectId,inquiryId) => {
  try {
    const response = await axios.post(`${API_PROJECT_URL}projects/${projectId}/inquiry/${inquiryId}`);
    console.log('Inquiry delete response:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al borrar requisicion: ", error);
    throw error;
  }
};

export const approveInquiry = async (projectId,inquiryId, userId) => {
  try {
    const response = await axios.post(`${API_PROJECT_URL}projects/${projectId}/inquiry/${inquiryId}/approve`, {
      userId, // Pass the ID of the logged-in user
    });
    return response.data;
  } catch (error) {
    console.error('Error approving inquiry:', error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Failed to approve inquiry');
  }
};


export const updateInquiry = async (projectId,inquiryId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/inquiry/${inquiryId}`);
    console.log('Inquiry update response:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al actualizar requisicion: ", error);
    throw error;
  }
};

export const addInquiry = async (projectId) => {
  try {
    const response = await axios.post(`${API_PROJECT_URL}projects/${projectId}/inquiry`);
    console.log('Inquiry add response:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al agregar requisicion: ", error);
    throw error;
  }
};



export const getQuoteItems = async (projectId,budgetId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/budget/${budgetId}`);
    console.log('Budget details data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener presupuestos: ", error);
    throw error;
  }
};
export const getProjects= async () => {
  try {
    const response = await axios.get(API_PROJECT_URL + 'projects');
    console.log('Protected data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener proyectos: ", error);
    throw error;
  }
};

export const deleteProject= async (projectId) => {
  try {
    const response = await axios.get(API_PROJECT_URL + 'projects');
    console.log('Protected data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al borrar el proyecto: ", error);
    throw error;
  }
};

export const getQuote = async (projectId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/quotes`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener cotizaciones: ", error);
    throw error;
  }
};

export const getPOs = async (projectId, inquiryId) => {
  try {
    const response = await axios.get(
      `${API_PROJECT_URL}projects/${projectId}/pos`, 
      { params: { inquiryId } } // Use params for query string
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener órdenes de compra: ", error);
    throw error;
  }
};

export const addPO = async (poData) => {
  const response = await fetch(`/api/pos`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(poData),
  });
  return response.json();
};

export const updatePO = async (poData) => {
  const response = await fetch(`/api/pos/${poData.po_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(poData),
  });
  return response.json();
};

export const deletePO = async (poId) => {
  await fetch(`/api/pos/${poId}`, { method: "DELETE" });
};

export const getNotifications = async (userId) => {
  const response = await axios.get(`${API_PROJECT_URL}notifications/${userId}`);
  return response.data;
};

// Mark a notification as read
export const markNotificationAsRead = async (notificationId) => {
  await axios.put(`${API_PROJECT_URL}notifications/${notificationId}/mark-read`);
};
