import React, { useEffect, useState } from "react";
import { ListGroup, Badge, Button, Container } from "react-bootstrap";
import { getNotifications, markNotificationAsRead } from "../services/api";

const NotificationsInbox = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    const data = await getNotifications(userId);
    setNotifications(data);
  };

  const handleMarkAsRead = async (notificationId) => {
    await markNotificationAsRead(notificationId);
    setNotifications((prev) =>
      prev.filter((notification) => notification.notification_id !== notificationId)
    );
  };

  return (
    <Container className="mt-4">
      <h2>Notificaciones</h2>
      {notifications.length === 0 ? (
        <p>No tienes notificaciones pendientes.</p>
      ) : (
        <ListGroup>
          {notifications.map((notification) => (
            <ListGroup.Item key={notification.notification_id}>
              <div className="d-flex justify-content-between align-items-center">
                <span>{notification.message}</span>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => handleMarkAsRead(notification.notification_id)}
                >
                  Marcar como leído
                </Button>
              </div>
              <Badge bg="secondary" className="mt-2">
                {new Date(notification.created_at).toLocaleString()}
              </Badge>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};

export default NotificationsInbox;
