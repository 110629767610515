// src/pages/Users.js
import React, { useEffect, useState } from 'react';
import { getUsers } from '../services/api'; // Import the API function

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch users when the component mounts
    const fetchUsers = async () => {
      try {
        const data = await getUsers(); // Call the Lambda function
        setUsers(data); // Set the fetched users in the state
        setLoading(false);
      } catch (err) {
        setError("Fallo al obtener usuarios");
        setLoading(false);
      }
    };

    fetchUsers(); // Call the function to fetch users
  }, []);

  // Handle loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h2 className="text-center mt-4">Users List</h2>
      <table className="table table-striped mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;

